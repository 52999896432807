import $ from 'jquery'

class Hero {
    constructor() {
        this.elements = {
            body: $('body')
        }

        window.addEventListener('scroll', function () {
            var scroll = $(window).scrollTop()
            $(".c-hero .c-hero__background").css({
                transform: 'translate3d(0%, -' + (scroll / 100) + '%, 0) scale(' + (100 + scroll / 50) / 100 + ')'
            })
        })
    }

    scaleBG() {
    }
}

export default Hero