import $ from 'jquery'

class wordWrap {
    constructor(element, wordtowrap) {
        this.wrapText(element, wordtowrap);
    }

    wrapText(element, wordtowrap) {
        $(element).html(function(){
            var words = $(this).text().split(' ');
            return words.slice(0, words.length - wordtowrap).join(' ') + " " + "<span class='italic'>" +
            words.slice(words.length-wordtowrap, words.length).join(' ') + "</span>";
        });

    }
}

export default wordWrap
