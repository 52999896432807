import $ from 'jquery'

class modalToggle {
    constructor(el) {
        $(el).on('click', (e) => {
            e.preventDefault();
            $('body').addClass('c-page-book--is-active');

            $('.c-tabbed-content__tabs li').each(function() {
                $(this).removeClass('is-active');
                $(this).children().removeClass('is-active');
            })
            $('.c-tabbed-content__tabs li:last-of-type').addClass('is-active');
            $('.c-tabbed-content__tabs li:last-of-type a').addClass('is-active');

            $('.c-tabbed-content__tab').each(function() {
                $(this).removeClass('active');
                $(this).css('display', 'none');
            })
            $('.c-tabbed-content__tab:last-of-type').addClass('active').css('display', 'block');
        });
    }
}

export default modalToggle