import scrollDir from 'scrolldir'
import NavToggle from './modules/NavToggle'
import fadeInNav from './modules/fadeInNav'
import wordWrap from './modules/wordWrap'
import escClasses from './modules/escClasses'
import Hero from './modules/Hero'
import ContentPostFilter from './modules/ContentPostFilter'
import modalToggle from './modules/modalToggle'
import CtaGridFilter from './modules/CTAgridfilter'

new scrollDir
new NavToggle
new Hero
new ContentPostFilter
new CtaGridFilter

new wordWrap('.c-quote__cite', 1)
new wordWrap('.c-media--hero .c-media__action .c-button', 1)
new wordWrap('.c-media-group--layout-content-left .c-media__button', 2)
new wordWrap('.c-media-group--layout-content-right .c-media__button', 2)
new wordWrap('.c-media-group--room-tiles .c-media__button', 2)
new wordWrap('.c-userflow__item__button', 2)
new wordWrap('.c-cta--layout-tile .c-cta__buttons .c-button', 2)
new escClasses('.s-page-wrap','c-page-book--is-active', 'body')

// custom JS modules - should probably refactor these.
new fadeInNav('.c-offcanvas--menu .menu > li', 1.3, .1)
new modalToggle('.c-button--open-table-modal')
