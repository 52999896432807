import $ from 'jquery'

class escClasses {

    constructor(element, classtoRemove, target) {

        this.elements = {
            el: $(element),
            classname: classtoRemove,
            target: $(element),
        }

        this.elements.el.on('click', this.hideOffcanvas(this.elements.classname, this.elements.target))
    }

    hideOffcanvas(classtoRemove, target) {
        $(target).removeClass(classtoRemove)
    }
}

export default escClasses
